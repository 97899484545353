import styled from "styled-components";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";

import IconArrowRight from "@assets/svg/arrow-right.svg";
import Link from "@components/atoms/Link";

export const Arrow = styled(IconArrowRight)`
  height: 1em;
  width: 1.3em;
  fill: currentColor;
  font-size: 0.9375rem;
  margin-top: 1rem;
  transition: ${({ theme }) => theme.transition};
  transition-property: transform;

  @media ${media.tablet} {
    margin-top: 2rem;
  }
`;

export const Wrapper = styled(Link)`
  min-height: 15.25rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  transition: ${({ theme }) => theme.transition};
  background-image: linear-gradient(
    to top,
    ${({ theme }) => theme.colors.secondaryBackground} 50%,
    ${({ theme }) => theme.colors.accent} 50%
  );
  background-position: 0 100%;
  background-size: 100% 201%;
  padding: 1.75rem 1.375rem 1.375rem;
  @media ${media.mobile} {
    min-height: 18rem;
  }
  @media ${media.tablet} {
    padding: 2.25rem 1.875rem 1.5rem;
    min-height: 22.6875rem;
    &:hover {
      background-position: 0 0;
      color: ${({ theme }) => theme.colors.white};
      transform: translateY(-0.625rem);

      ${Arrow} {
        transform: translateX(0.625rem);
      }
    }
  }
`;

export const Bottom = styled.span`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

export const Media = styled.span`
  display: flex;
  align-items: center;
  svg {
    height: 1em;
    width: 1em;
    fill: currentColor;
    font-size: 2.625rem;
    @media ${media.tablet} {
      font-size: 2.8125rem;
    }
  }
`;

export const Description = styled(Typography).attrs({
  variant: "body1",
  as: "span"
})`
  white-space: pre-line;
  word-break: break-word;
  margin-bottom: 1rem;
  line-height: 1.5;
`;
