import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";

import { ServiceTileProps } from "./types";
import * as Styled from "./styles";

const ServiceTile: React.FC<ServiceTileProps> = ({
  title,
  icon,
  description,
  link: { href, rel, target }
}) => (
  <Styled.Wrapper {...{ href, rel, target }}>
    <Styled.Media>{icon}</Styled.Media>
    <Styled.Bottom className="service-tile-bottom">
      <Typography variant="body3" as="span" fontWeight="medium">
        {title}
      </Typography>
      {description && (
        <Styled.Description>{description}</Styled.Description>
      )}
      <Styled.Arrow />
    </Styled.Bottom>
  </Styled.Wrapper>
);

export default ServiceTile;
